var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "errorContainer",
        { attrs: { error: _vm.erreur, warning: _vm.warning } },
        [
          _vm.clientsList
            ? _c(
                "b-row",
                [
                  !_vm.$screen.sm
                    ? _c("b-col", { attrs: { md: "12" } }, [
                        _c(
                          "h1",
                          {
                            class: [
                              `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                              ,
                              { "is-pwa": _vm.$isPwa() },
                            ],
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(8, "Client list")) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", xl: "12" } },
                    [
                      _c(
                        "transition",
                        { attrs: { name: "slide" } },
                        [
                          _c(
                            "b-card",
                            { attrs: { "no-body": "" } },
                            [
                              _vm.$screen.sm
                                ? _c("b-card-header", [
                                    _c("h4", [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(8, "Client list"))
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _c(
                                "b-card-body",
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _vm.$screen.width >= 992
                                        ? _c(
                                            "b-col",
                                            {
                                              staticClass: "my-1",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  staticClass: "mb-0",
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      1,
                                                      "Filter"
                                                    ),
                                                    "label-cols": "3",
                                                    "label-align-sm": "left",
                                                    "label-size": "sm",
                                                    "label-for": "filterInput",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    { attrs: { size: "sm" } },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          type: "search",
                                                          id: "filterInput",
                                                          placeholder:
                                                            _vm.FormMSG(
                                                              564450,
                                                              "Type to Search"
                                                            ),
                                                        },
                                                        model: {
                                                          value: _vm.filter,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.filter = $$v
                                                          },
                                                          expression: "filter",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-append",
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  !_vm.filter,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.filter =
                                                                      ""
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-times",
                                                                attrs: {
                                                                  "aria-hidden":
                                                                    "true",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            cols: `${
                                              _vm.$screen.width >= 992 ? 6 : 12
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              class: `${
                                                _vm.$screen.width >= 992
                                                  ? "pull-right"
                                                  : ""
                                              }`,
                                            },
                                            [
                                              _c("b-button", {
                                                attrs: {
                                                  size: "md",
                                                  variant: "success",
                                                  block: _vm.$screen.md,
                                                },
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.FormMSG(
                                                      56445,
                                                      "New client"
                                                    )
                                                  ),
                                                },
                                                on: {
                                                  click: _vm.handleNewClient,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", xl: "12" } },
                                        [
                                          _vm.$screen.width < 992
                                            ? _c("CardListBuilder", {
                                                attrs: {
                                                  items: _vm.clientsList,
                                                  fields:
                                                    _vm.clientsMobileFields,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "actions",
                                                      fn: function (data) {
                                                        return [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "btn bg-transparent border-0",
                                                              attrs: {
                                                                variant:
                                                                  "primary",
                                                                size: "sm",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.redirectToClient(
                                                                      data.item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS.EDIT
                                                                    .name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .EDIT
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mr-1 btn bg-transparent border-0",
                                                              attrs: {
                                                                variant:
                                                                  "success",
                                                                size: "sm",
                                                                title:
                                                                  _vm.FormMSG(
                                                                    51,
                                                                    "Send email"
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.sendEmail(
                                                                      data.item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS.MAIL
                                                                    .name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .MAIL
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mr-1 btn bg-transparent border-0",
                                                              attrs: {
                                                                variant:
                                                                  "success",
                                                                disabled:
                                                                  data.item
                                                                    .phone
                                                                    .length < 1,
                                                                size: "sm",
                                                                title:
                                                                  _vm.FormMSG(
                                                                    52,
                                                                    "Call by phone"
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.callPhone(
                                                                      data.item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS
                                                                    .PHONE.name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .PHONE
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "btn bg-transparent border-0",
                                                              attrs: {
                                                                variant:
                                                                  "danger",
                                                                size: "sm",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.delItem(
                                                                      data.item
                                                                        .id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS
                                                                    .TRASH.name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .TRASH
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2846263846
                                                ),
                                              })
                                            : _vm._e(),
                                          _vm.$screen.width >= 992
                                            ? _c("b-table", {
                                                ref: "myTable",
                                                staticStyle: {
                                                  "text-align": "left",
                                                },
                                                attrs: {
                                                  hover: _vm.hover,
                                                  responsive: "sm",
                                                  selectable: "",
                                                  "sticky-header": "700px",
                                                  selectedVariant:
                                                    _vm.selectedColor,
                                                  "select-mode": _vm.selectMode,
                                                  items: _vm.clientsList,
                                                  fields: _vm.clientsListFields,
                                                  "current-page":
                                                    _vm.currentPage,
                                                  filter: _vm.filter,
                                                  "per-page": _vm.perPage,
                                                  bordered: "",
                                                  small: "",
                                                  "head-variant": _vm.hv,
                                                  "empty-text": _vm.FormMSG(
                                                    564451,
                                                    "No customer found"
                                                  ),
                                                  "show-empty": "",
                                                },
                                                on: {
                                                  "row-clicked":
                                                    _vm.redirectToClient,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "cell(actions)",
                                                      fn: function (data) {
                                                        return [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mr-1 btn bg-transparent border-0",
                                                              attrs: {
                                                                variant:
                                                                  "success",
                                                                size: "sm",
                                                                title:
                                                                  _vm.FormMSG(
                                                                    51,
                                                                    "Send email"
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.sendEmail(
                                                                      data.item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS.MAIL
                                                                    .name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .MAIL
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mr-1 btn bg-transparent border-0",
                                                              attrs: {
                                                                variant:
                                                                  "success",
                                                                disabled:
                                                                  data.item
                                                                    .phone
                                                                    .length < 1,
                                                                size: "sm",
                                                                title:
                                                                  _vm.FormMSG(
                                                                    52,
                                                                    "Call by phone"
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.callPhone(
                                                                      data.item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS
                                                                    .PHONE.name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .PHONE
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "cell(rem)",
                                                      fn: function (data) {
                                                        return [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "btn bg-transparent border-0",
                                                              attrs: {
                                                                variant:
                                                                  "danger",
                                                                size: "sm",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.delItem(
                                                                      data.item
                                                                        .id,
                                                                      data.item
                                                                        .addressId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS
                                                                    .TRASH.name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .TRASH
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1455838896
                                                ),
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("loading", {
                                    attrs: {
                                      active: _vm.isLoading,
                                      "is-full-page": true,
                                    },
                                    on: {
                                      "update:active": function ($event) {
                                        _vm.isLoading = $event
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }