<style scoped>
.card-body >>> table > tbody > tr > td {
	cursor: pointer;
}

input[type='texte'] {
	width: 100%;
	padding: 12px 20px;
	margin: 4px 0;
	box-sizing: border-box;
	border: none;
	background-color: white;
	color: white;
}
</style>

<template>
	<div>
		<errorContainer :error="erreur" :warning="warning">
			<b-row v-if="clientsList">
				<b-col v-if="!$screen.sm" md="12">
					<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">
						{{ FormMSG(8, 'Client list') }}
					</h1>
				</b-col>
				<b-col cols="12" xl="12">
					<transition name="slide">
						<!-- bg-variant="secondary" text-variant="white"  -->
						<!-- bg-secondary text-white -->
						<b-card no-body>
							<b-card-header v-if="$screen.sm">
								<!--<h1> {{bob}} </h1> -->
								<h4>{{ FormMSG(8, 'Client list') }}</h4>
							</b-card-header>
							<b-card-body>
								<b-row>
									<b-col cols="6" class="my-1" v-if="$screen.width >= 992">
										<b-form-group
											:label="FormMSG(1, 'Filter')"
											label-cols="3"
											label-align-sm="left"
											label-size="sm"
											label-for="filterInput"
											class="mb-0"
										>
											<b-input-group size="sm">
												<b-form-input
													v-model="filter"
													type="search"
													id="filterInput"
													:placeholder="FormMSG(564450, 'Type to Search')"
												/>
												<b-input-group-append>
													<b-button :disabled="!filter" @click="filter = ''"><i class="fa fa-times" aria-hidden="true"></i></b-button>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col :cols="`${$screen.width >= 992 ? 6 : 12}`">
										<b-form-group :class="`${$screen.width >= 992 ? 'pull-right' : ''}`">
											<b-button
												v-html="FormMSG(56445, 'New client')"
												@click="handleNewClient"
												size="md"
												variant="success"
												:block="$screen.md"
											></b-button>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col cols="12" xl="12">
										<CardListBuilder v-if="$screen.width < 992" :items="clientsList" :fields="clientsMobileFields">
											<template slot="actions" slot-scope="data">
												<b-button class="btn bg-transparent border-0" variant="primary" size="sm" @click="redirectToClient(data.item)">
													<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
												</b-button>
												<b-button
													variant="success"
													size="sm"
													@click="sendEmail(data.item)"
													class="mr-1 btn bg-transparent border-0"
													:title="FormMSG(51, 'Send email')"
												>
													<component :is="getLucideIcon(ICONS.MAIL.name)" :color="ICONS.MAIL.color" :size="20" />
												</b-button>
												<b-button
													variant="success"
													:disabled="data.item.phone.length < 1"
													size="sm"
													@click="callPhone(data.item)"
													class="mr-1 btn bg-transparent border-0"
													:title="FormMSG(52, 'Call by phone')"
												>
													<component :is="getLucideIcon(ICONS.PHONE.name)" :color="ICONS.PHONE.color" :size="20" />
												</b-button>
												<b-button class="btn bg-transparent border-0" variant="danger" size="sm" @click.stop="delItem(data.item.id)">
													<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
												</b-button>
											</template>
										</CardListBuilder>
										<b-table
											v-if="$screen.width >= 992"
											:hover="hover"
											responsive="sm"
											ref="myTable"
											selectable
											sticky-header="700px"
											:selectedVariant="selectedColor"
											:select-mode="selectMode"
											:items="clientsList"
											style="text-align: left"
											:fields="clientsListFields"
											:current-page="currentPage"
											:filter="filter"
											:per-page="perPage"
											@row-clicked="redirectToClient"
											bordered
											small
											:head-variant="hv"
											:empty-text="FormMSG(564451, 'No customer found')"
											show-empty
										>
											<template v-slot:cell(actions)="data">
												<b-button
													variant="success"
													size="sm"
													@click="sendEmail(data.item)"
													class="mr-1 btn bg-transparent border-0"
													:title="FormMSG(51, 'Send email')"
												>
													<component :is="getLucideIcon(ICONS.MAIL.name)" :color="ICONS.MAIL.color" :size="20" />
												</b-button>
												<b-button
													variant="success"
													:disabled="data.item.phone.length < 1"
													size="sm"
													@click="callPhone(data.item)"
													class="mr-1 btn bg-transparent border-0"
													:title="FormMSG(52, 'Call by phone')"
												>
													<component :is="getLucideIcon(ICONS.PHONE.name)" :color="ICONS.PHONE.color" :size="20" />
												</b-button>
											</template>
											<template v-slot:cell(rem)="data">
												<b-button
													variant="danger"
													size="sm"
													@click="delItem(data.item.id, data.item.addressId)"
													class="btn bg-transparent border-0"
												>
													<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
												</b-button>
											</template>
										</b-table>
									</b-col>
								</b-row>
								<loading :active.sync="isLoading" :is-full-page="true"></loading>
							</b-card-body>
						</b-card>
					</transition>
				</b-col>
			</b-row>
		</errorContainer>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import { store } from '@/store';
import { getClients, deleteClient, addClient } from '@/cruds/clients.crud';
import languageMessages from '@/mixins/languageMessages';
import { addressFormatter } from '@/shared/fomaters';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'ClientsListingPage',
	components: { Loading },
	mixins: [languageMessages, globalMixin],
	data() {
		return {
			filter: '',
			erreur: {},
			warning: '',
			hv: 'dark',
			perPage: 0,
			currentPage: 1,
			selectedColor: 'primary',
			isLoading: false,
			hover: true,
			selectMode: 'single',

			clientsList: []
		};
	},
	computed: {
		/**
		 * @return {Array}
		 */
		baseFields() {
			return [
				{
					key: 'name',
					label: this.FormMSG(3, 'Name'),
					sortable: true
				},
				{
					key: 'address',
					label: this.FormMSG(256445, 'Address'),

					sortable: true,
					formatter: (v) => addressFormatter(v)
				},
				{
					key: 'actions',
					label: this.FormMSG(123123, 'Actions'),
					sortable: false,
					class: 'text-center',
					formatter: (v) => v
				},
				{
					key: 'rem',
					label: this.FormMSG(7, 'Delete'),
					sortable: false,
					class: 'text-center',
					formatter: (v) => v
				}
			];
		},
		clientsListFields() {
			return this.baseFields;
		},
		clientsMobileFields() {
			const keys = ['actions', 'rem'];
			return this.baseFields.filter((t) => !keys.includes(t.key));
		},
		/**
		 * @return {Object}
		 */
		rendConfirmModalTxt() {
			const _t = this.FormMSG;
			return {
				title: _t(56561, 'Please Confirm'),
				okTitle: _t(56562, 'YES'),
				cancelTitle: _t(56563, 'NO')
			};
		}
	},
	created() {
		this.initClientsList();
	},
	methods: {
		async initClientsList() {
			this.isLoading = true;
			this.clientsList = (await getClients()).clients;
			this.isLoading = false;
		},

		redirectToClient(client) {
			this.$router.push(`clientsList/${client.id}`);
		},

		/**
		 * @param {Object} client
		 */
		handleNewClient() {
			this.$router.push(`clientsList/add`);
		},

		/**
		 * @param {Array} items
		 * @return {Number}
		 */
		getRowCount(items) {
			return items.length;
		},

		sendEmail(item) {
			var email = item.email;
			var subject = this.FormMSG(45, 'Subject');
			var emailBody =
				this.FormMSG(46, 'Hello') + ' ' + item.firstName + ',%0D%0A%0D%0A' + store.state.myProfile.firstName + ' ' + store.state.myProfile.name;
			document.location = 'mailto:' + email + '?body=' + emailBody + '&subject=' + subject;
			//'<html><p> '  + '</p></html>' ?body=Hello1&subject=test subject1"
		},

		callPhone(item) {
			window.location.href = 'tel://' + item.phone;
		},

		/**
		 * @param {String|Number} id
		 */
		delItem(id, addressId) {
			const txt = this.FormMSG(564452, 'Please confirm that you want to delete this customer');

			this.$bvModal
				.msgBoxConfirm(txt, {
					title: this.rendConfirmModalTxt.title,
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.rendConfirmModalTxt.okTitle,
					cancelTitle: this.rendConfirmModalTxt.cancelTitle,
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then((v) => {
					if (!v) return;
					deleteClient(id, addressId).then(() => {
						const index = this.clientsList.findIndex((c) => c.id === id);
						if (index !== -1) this.clientsList.splice(index, 1);

						this.createToastForMobile(this.FormMSG(54, 'Success'), this.FormMSG(53, 'Delete successfull'), '', 'success');
					});
				})
				.catch((err) => {
					this.createToastForMobile(this.FormMSG(55, 'Error'), err.message, '', 'danger');
				});
		}
	}
};
</script>
